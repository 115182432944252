




















import { Component, Vue, Prop } from 'vue-property-decorator'

import { uniqueId } from '@/lib/utils/uniqueId'
import { ModalOptions } from '@/lib/interfaces/ModalOptions.interface'
import { IOverlayContainer } from '@/lib/interfaces/OverlayContainer.interface'

@Component<OverlayContainer>({})
export default class OverlayContainer extends Vue implements IOverlayContainer {
  key: string = ''
  component?: (new () => Vue) | null = null
  props?: { [key: string]: any }
  style: { [key: string]: string } = {}

  isVisible = false
  opened = false

  loadedCallback?: (component: any) => void

  constructor() {
    super()
    this.key = uniqueId()
  }

  updated() {
    if (this.loadedCallback) {
      const component = this.$refs.component
      if (!Array.isArray(component) && !(component instanceof Element)) {
        component!.$forceUpdate()
      }
      this.loadedCallback(component)
      this.loadedCallback = undefined
      this.show()
    }
  }

  async show() {
    this.isVisible = true
    await this.$nextTick()
    this.opened = true
  }

  close() {
    this.opened = false
    setTimeout(() => {
      this.isVisible = false
      this.component = null
    }, 500)
    const component = this.$refs.component as Vue & { onModalClose?: any }
    if (typeof component.onModalClose === 'function') {
      component.onModalClose()
    }
  }

  async loadComponent<T extends Vue>(
    component: new () => T,
    options: ModalOptions
  ) {
    this.props = options.props || {}
    this.style = options.style || {}
    this.component = component

    this.key = uniqueId()
    return new Promise<T>((res, rej) => {
      this.loadedCallback = res
    })
  }
}
