














import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'

@Component<RegisterDriverCompleteView>({
  components: {
    Header
  }
})
export default class RegisterDriverCompleteView extends Vue {}
