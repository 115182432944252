






import { Component, Vue, Prop } from 'vue-property-decorator'
@Component<Page>({})
export default class Page extends Vue {
  @Prop()
  title?: string
}
