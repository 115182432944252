





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<RegisterInfo>({})
export default class RegisterInfo extends Vue {
  advance() {
    this.$emit('advance')
  }
}
