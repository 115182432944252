







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

import Header from '@/components/Header.vue'
import ContainerCard from '@/components/ContainerCard.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'

import Drivers from '@/graphql/queries/Drivers.gql'
import {
  IDriversData,
  IDriver
} from '@/graphql/queries/interfaces/Drivers.interface'
import InviteDriver from '@/graphql/userService/InviteDriver.gql'
import { IInviteDriverData } from '@/graphql/userService/interfaces/inviteDriver.interface'
import UpdateDriverStatus from '@/graphql/mutations/UpdateDriverStatus.gql'
import ResendDriverInvite from '@/graphql/userService/ResendDriverInvite.gql'

import { DriverStatus } from '@/lib/interfaces/DriverStatus.enum'
import { OverlayController } from '../lib/utils/OverlayController'

@Component<CompanyDriversView>({
  components: {
    Header,
    ContainerCard
  }
})
export default class CompanyDriversView extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
  }
  DriverStatus = DriverStatus
  drivers: object = {}

  newDriver = {
    lastname: '',
    forename: '',
    email: ''
  }

  mounted() {
    this.getDrivers()
  }

  async getDrivers() {
    const { data } = await this.$apollo.query<IDriversData>({
      query: Drivers,
      fetchPolicy: 'no-cache'
    })
    this.drivers = data.users.edges
    this.$forceUpdate()
  }

  async inviteDriver() {
    const valid = await this.$refs.observer.validate()
    if (valid) {
      try {
        const { data } = await this.$apollo.mutate<IInviteDriverData>({
          mutation: InviteDriver,
          variables: this.newDriver
        })
        if (data && data.inviteDriver === 'success') {
          this.showInviteResponse(true)
          this.getDrivers()
        } else {
          throw new Error()
        }
      } catch (error) {
        this.showInviteResponse(false)
      }
    }
  }

  async resendInvite(email: string) {
    await this.$apollo.mutate({
      mutation: ResendDriverInvite,
      variables: {
        email
      }
    })
    this.$toast.open({ type: 'success', message: `${this.$t('toast.success.driverInviteSend')}` })
  }

  getStatus(status: DriverStatus) {
    switch (status) {
      case DriverStatus.Active:
        return this.$t('driver.table.body.status.active')
      case DriverStatus.Inactive:
        return this.$t('driver.table.body.status.deactivate')
      case DriverStatus.InviteSend:
        return this.$t('driver.table.body.status.sentInvitation')
    }
  }

  async updateDriverStatus(id: string, status: string) {
    await this.$apollo.mutate({
      mutation: UpdateDriverStatus,
      variables: {
        id,
        status
      }
    })
    this.getDrivers()
  }

  async showInviteResponse(success: boolean) {
    const confirmComponent = await OverlayController.loadComponent(
      ConfirmModal,
      {
        props: {
          text: success
            ? this.$t('driver.invite.succsess')
            : this.$t('driver.invite.failure')
        }
      }
    )
  }
}
