









import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import RegisterInfo from '@/components/registerCompany/Info.vue'
import RegisterForm from '@/components/registerCompany/Form.vue'
import RegisterComplete from '@/components/registerCompany/Complete.vue'

@Component<RegisterCompanyView>({
  components: {
    Header,
    RegisterInfo,
    RegisterForm,
    RegisterComplete
  }
})
export default class RegisterCompanyView extends Vue {
  state: State = State.Info
  setState(newState: State) {
    this.state = newState
  }
}

enum State {
  Info = 'info',
  Form = 'form',
  Complete = 'complete'
}
