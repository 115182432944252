import Vue from 'vue'

import App from '@/App.vue'
import router from '@/router'
import { store } from '@/store'
import { i18n } from '@/i18n/i18n'
import { createProvider } from '@/vue-apollo'
import '@/toast-notification'
import '@/vue-head'
import '@/validation'

Vue.config.productionTip = false

export const apolloProvider = createProvider()
export const apolloClient = apolloProvider.defaultClient

export const vueInstance = new Vue({
  router,
  store,
  render: (h) => h(App),
  apolloProvider,
  i18n
}).$mount('#app')
