import Vue, { VueConstructor } from 'vue'
import { Subscription } from 'rxjs'
import { IOverlayContainer } from '@/lib/interfaces/OverlayContainer.interface'
import { ModalOptions } from '@/lib/interfaces/ModalOptions.interface'

export class OverlayController {

  static registerOverlayContainer(overlayContainer: IOverlayContainer) {
    this.overlayContainer = overlayContainer
  }

  static async loadComponent<T extends Vue>(component: new () => T, options: ModalOptions): Promise<T> {

    return await this.overlayContainer.loadComponent(component, options)

  }

  static isThatComponentLoaded<T extends Vue>(component: T) {
    return this.overlayContainer.$refs.component === component
  }

  static close() {
    this.overlayContainer.close()
    this.closeSubscriptions()
  }

  static manageSubsriptions(...subsriptions: Subscription[]) {
    this.subsriptions.push(...subsriptions)
  }

  private static overlayContainer: IOverlayContainer
  private static subsriptions: Subscription[] = []

  private static closeSubscriptions() {
    for (const subscription of this.subsriptions) {
      subscription.unsubscribe()
    }
    this.subsriptions = []
  }
}