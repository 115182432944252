





import { Component, Vue } from 'vue-property-decorator'
@Component<BlankLayout>({
})
export default class BlankLayout extends Vue {}
