







import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import RequestPasswordResetForm from '@/components/RequestPasswordResetForm.vue'

@Component<RequestPasswordResetView>({
  components: {
    Header,
    RequestPasswordResetForm
  }
})
export default class RequestPasswordResetView extends Vue {
//   get email() {
//     return this.$route.query.email
//   }

//   get token() {
//     return this.$route.query.token
//   }
}
