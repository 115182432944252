








import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<LoadingDots>({})
export default class LoadingDots extends Vue {}
