




















import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import ResetPassword from '@/graphql/userService/ResetPassword.gql'
import { IResetPassword } from '@/graphql/userService/interfaces/ResetPassword.interface'
import { UserServiceErrors } from '@/graphql/userService/interfaces/UserServiceErrors'

import { RoleName } from '@/lib/interfaces/RoleName.enum'

@Component<ResetPasswordView>({})
export default class ResetPasswordView extends Vue {
  password = ''
  passwordConfirm = ''

  errors: Array<string | TranslateResult> = []

  @Prop({ required: true })
  email!: string
  @Prop({ required: true })
  token!: string
  @Prop()
  submit?: string

  get arePasswordsSame() {
    const areSame = this.password === this.passwordConfirm
    const error = this.$t('resetPassword.errors.same')
    if (!areSame && !this.errors.includes(error)) {
      this.errors.push(error)
    } else if (areSame && this.errors.includes(error)) {
      this.errors.splice(this.errors.indexOf(error))
    }
    return areSame
  }

  async setPassord() {
    const email = this.email
    const token = this.token

    this.errors = []
    if (email == null || token == null) {
      this.errors.push(this.$t('resetPassword.errors.emailOrToken'))
    }
    if (this.password.length < 6) {
      this.errors.push(this.$t('resetPassword.errors.length'))
    }
    if (!this.arePasswordsSame) {
      this.errors.push(this.$t('resetPassword.errors.same'))
    }
    if (this.errors.length > 0) {
      return
    }

    try {
      const { data } = await this.$apollo.mutate<IResetPassword>({
        mutation: ResetPassword,
        variables: { newPassword: this.password, email, token }
      })
      if (data == null) {
        throw new Error('Response is null')
      }

      const res = data.resetPasswordWithToken

      if (
        res.userType === RoleName.Company ||
        res.userType === RoleName.Organizer
      ) {
        this.$router.push('login')
      }
      if (res.userType === RoleName.Driver) {
        this.$router.push('registration-complete')
      }
      this.$toast.open({ type: 'success', message: `${this.$t('toast.success.passwordSet')}` })
    } catch (error) {
      if (
        error.message &&
        error.message.includes(UserServiceErrors.EmailOrToken)
      ) {
        this.errors.push(this.$t('resetPassword.errors.emailOrToken'))
      }
    }
  }
}
