import Vue from 'vue'
import Vuex from 'vuex'

import { IMe } from '@/graphql/queries/interfaces/Me.interface'

Vue.use(Vuex)

export const store = new Vuex.Store<IStoreState>({
  state: {
    me: undefined
  },
  mutations: {
    me(state, me) {
      state.me = me
    }
  }
})

export interface IStoreState {
  me?: IMe
}
