














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<ContainerCard>({})
export default class ContainerCard extends Vue {
  @Prop() hint?: string
  @Prop() title!: string
  @Prop() info!: string
  @Prop({ required: true }) head!: string

  get hasHint() {
    return typeof this.hint === 'string' && this.hint !== ''
  }

  get hasTitle() {
    return typeof this.title === 'string' && this.title !== ''
  }

  get hasInfo() {
    return typeof this.info === 'string' && this.info !== ''
  }
}
