













import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<Footer>({})
export default class Footer extends Vue {}
