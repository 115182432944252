export enum CompanyOrder {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  ACL_ASC = 'ACL_ASC',
  ACL_DESC = 'ACL_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  phone_ASC = 'phone_ASC',
  phone_DESC = 'phone_DESC',
  zip_ASC = 'zip_ASC',
  zip_DESC = 'zip_DESC',
  street_ASC = 'street_ASC',
  street_DESC = 'street_DESC',
  geoPoint_ASC = 'geoPoint_ASC',
  geoPoint_DESC = 'geoPoint_DESC',
  country_ASC = 'country_ASC',
  country_DESC = 'country_DESC',
  city_ASC = 'city_ASC',
  city_DESC = 'city_DESC',
  email_ASC = 'email_ASC',
  email_DESC = 'email_DESC',
  contactPerson_ASC = 'contactPerson_ASC',
  contactPerson_DESC = 'contactPerson_DESC',
  paymentInfo_ASC = 'paymentInfo_ASC',
  paymentInfo_DESC = 'paymentInfo_DESC',
  active_ASC = 'active_ASC',
  active_DESC = 'active_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC'
}
