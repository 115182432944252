var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContainerCard',{attrs:{"head":_vm.$t('company.profile.form.title')}},[_c('ValidationObserver',{ref:"observer",staticClass:"registrationForm"},[_c('div',{staticClass:"step userData"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('register.form.step1')))]),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.companyName')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.name),expression:"company.name"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.companyNamePh')},domProps:{"value":(_vm.company.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "name", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.adress')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.street),expression:"company.street"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.streetPh')},domProps:{"value":(_vm.company.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "street", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.zip),expression:"company.zip"}],attrs:{"type":"text","patern":"[0-9]{5}","placeholder":_vm.$t('register.form.zipPh')},domProps:{"value":(_vm.company.zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "zip", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.city),expression:"company.city"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.cityPh')},domProps:{"value":(_vm.company.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "city", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.country),expression:"company.country"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.countryPh')},domProps:{"value":(_vm.company.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "country", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"step paymentInfo"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('register.form.step2')))]),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.contactInfo')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.contactPerson),expression:"company.contactPerson"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.contactInfoNamePh')},domProps:{"value":(_vm.company.contactPerson)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "contactPerson", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.phone),expression:"company.phone"}],attrs:{"type":"tel","placeholder":_vm.$t('register.form.contactInfoPhonePh')},domProps:{"value":(_vm.company.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "phone", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.email),expression:"company.email"}],attrs:{"type":"email","disabled":"","placeholder":_vm.$t('register.form.contactInfoMailPh')},domProps:{"value":(_vm.company.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "email", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.recipient')))]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.recipient),expression:"company.recipient"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.recipientPh')},domProps:{"value":(_vm.company.recipient)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "recipient", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.iban')))]),_c('ValidationProvider',{attrs:{"rules":"required|iban"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.iban),expression:"company.iban"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.ibanPh')},domProps:{"value":(_vm.company.iban)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "iban", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('strong',[_vm._v(_vm._s(_vm.$t('register.form.bic')))]),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.bic),expression:"company.bic"}],attrs:{"type":"text","placeholder":_vm.$t('register.form.bicPh')},domProps:{"value":(_vm.company.bic)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "bic", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t('company.profile.form.submit')))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }