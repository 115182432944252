







import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

@Component<DefaultLayout>({
  components: {
    Header,
    Footer
  }
})
export default class DefaultLayout extends Vue {}
