








import { Component, Vue, Prop } from 'vue-property-decorator'
import { OverlayController } from '../lib/utils/OverlayController'

@Component<ConfirmModal>({})
export default class ConfirmModal extends Vue {
  @Prop()
  text?: string

  onCancel: () => void = () => void 0
  onConfirm: () => void = () => void 0

  private cancel() {
    this.onCancel()
    OverlayController.close()
  }
  private confirm() {
    this.onConfirm()
    OverlayController.close()
  }
}
