import Vue from 'vue'
import IBAN from 'iban'
import { required, email } from 'vee-validate/dist/rules'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import { i18n } from '@/i18n/i18n'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend('email', {
  ...email,
  message: i18n.t('validator.email').toString()
})
extend('required', {
  ...required,
  message: i18n.t('validator.required').toString()
})
extend('phone', {
  validate(value) {
    const regEx = /^\+?(\d+ ?)$/
    return regEx.test(value)
  },
  message: i18n.t('validator.phone').toString()
})
extend('zip', {
  validate(value) {
    const regEx = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/
    return regEx.test(value)
  },
  message: i18n.t('validator.zip').toString()
})
extend('iban', {
  validate(value) {
    return IBAN.isValid(value)
  },
  message: i18n.t('validator.iban').toString()
})
