













import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import RequestPasswordReset from '@/graphql/userService/RequestPasswordReset.gql'
import { IRequestPasswordResetData } from '@/graphql/userService/interfaces/RequestPasswordReset.interface'
import { UserServiceErrors } from '@/graphql/userService/interfaces/UserServiceErrors'

@Component<RequestPasswordResetForm>({})
export default class RequestPasswordResetForm extends Vue {
  email = ''

  errors: Array<string | TranslateResult> = []

  @Prop()
  submit?: string

  async sendResetMail() {
    const email = this.email

  //   this.errors = []
  //   if (email == null || token == null) {
  //     this.errors.push(this.$t('resetPassword.errors.emailOrToken'))
  //   }
  //   if (this.password.length < 6) {
  //     this.errors.push(this.$t('resetPassword.errors.length'))
  //   }
  //   if (!this.arePasswordsSame) {
  //     this.errors.push(this.$t('resetPassword.errors.same'))
  //   }
  //   if (this.errors.length > 0) {
  //     return
  //   }

    try {
      const { data } = await this.$apollo.mutate<IRequestPasswordResetData>({
        mutation: RequestPasswordReset,
        variables: { email }
      })
      if (data == null) {
        throw new Error('Response is null')
      }

      this.$router.push('login')
      this.$toast.open({ type: 'success', message: `${this.$t('toast.success.requestPasswordReset')}` })
    } catch (error) {
      if (
        error.message &&
        error.message.includes(UserServiceErrors.EmailOrToken)
      ) {
        this.errors.push(this.$t('resetPassword.errors.emailOrToken'))
      }
      this.$toast.open({ type: 'error', message: `${this.$t('toast.Something went wrong')}` })
    }
  }
}
