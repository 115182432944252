import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { 
  createApolloClient,
  restartWebsockets,
  ApolloClientClientConfig
} from 'vue-cli-plugin-apollo/graphql-client'

import router from '@/router'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
export const AUTH_TOKEN = 'session-token'

// Http endpoint
const httpEndpoint = `${process.env.VUE_APP_PARSE_SERVER || `http://localhost:3000`}/graphql`
const httpLink = new HttpLink({
  uri: httpEndpoint
})
// Files URL root
export const filesRoot = process.env.VUE_APP_FILES_ROOT || httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'))

Vue.prototype.$filesRoot = filesRoot

const authLink = setContext((operation, context) => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const headers: any = {
    'X-Parse-Application-Id': process.env.VUE_APP_PARSE_APP_ID,
    'X-Parse-JavaScript-key': process.env.VUE_APP_PARSE_JS_KEY || 'N/A'
  }

  if (token) {
    headers['X-Parse-Session-Token'] = token
  }

  return {
    ...context,
    headers: {
      ...(context.headers),
      ...headers
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // tslint:disable-next-line: no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
      if (message === 'Invalid session token') {
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem(AUTH_TOKEN)
        }
        router.push('login')
      }

    }
    )
  }
  if (networkError) {
    // tslint:disable-next-line: no-console
    console.log(networkError.message)
  }
})

// Config
const defaultOptions: ApolloClientClientConfig<InMemoryCache> = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null as unknown as string,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // httpLinkOptions: httpLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  apollo: { link: authLink.concat(errorLink).concat(httpLink) } as any

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options
  }) as { apolloClient: ApolloClient<InMemoryCache>, wsClient: SubscriptionClient }
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    errorHandler(error) {
      // tslint:disable-next-line: max-line-length tslint:disable-next-line: no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    }
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient: ApolloClient<InMemoryCache>, token: string) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) { restartWebsockets(apolloClient.wsClient) }
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient: ApolloClient<InMemoryCache>) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  try {
    if (apolloClient.wsClient) { restartWebsockets(apolloClient.wsClient) }
    await apolloClient.resetStore()
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
