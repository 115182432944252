







import { Component, Vue, Prop } from 'vue-property-decorator'
import Form from '@/components/CompanyProfileForm.vue'

@Component<RegisterCompanyView>({
  components: {
    Form,
  }
})
export default class RegisterCompanyView extends Vue {
}

