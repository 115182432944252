









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component<RegisterComplete>({})
export default class RegisterComplete extends Vue {}
