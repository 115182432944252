














import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import ConfirmModal from '@/components/ConfirmModal.vue'

import { onLogin } from '@/vue-apollo'
import Login from '@/graphql/mutations/Login.gql'
import { ILoginData } from '@/graphql/mutations/interfaces/Login.interface'

import { RoleName } from '@/lib/interfaces/RoleName.enum'
import { OverlayController } from '../lib/utils/OverlayController'

@Component<LoginView>({
  components: {
    Header
  },
  // @ts-ignore
  head: {
    title () {
      return {
        inner: `${this.$t('Login')}`
      }
    }
  }
})
export default class LoginView extends Vue {
  email = ''
  password = ''
  error = false

  async mounted() {
    const me = this.$store.state.me
    if (me != null) {
      if (me.type === RoleName.Company) {
        this.$router.push('drivers')
      }
      if (me.type === RoleName.Organizer) {
        this.$router.push('companies')
      }
    }
  }

  onEnter(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      e.preventDefault()
      this.login()
    }
  }

  async login() {
    try {
      this.error = false
      const { data } = await this.$apollo.mutate<ILoginData>({
        mutation: Login,
        variables: { username: this.email, password: this.password }
      })

      if (data == null) {
        throw new Error('no data returned')
      } else if (
        data.logIn.viewer.user.type !== RoleName.Company &&
        data.logIn.viewer.user.type !== RoleName.Organizer
      ) {
        this.showWrongAccountNotice()
        return
      }

      await onLogin(
        this.$apollo.provider.defaultClient,
        data.logIn.viewer.sessionToken
      )

      if (data.logIn.viewer.user.type === RoleName.Company) {
        this.$router.push('drivers')
      } else if (data.logIn.viewer.user.type === RoleName.Organizer) {
        this.$router.push('companies')
      }

      this.$toast.open({ message: `${this.$t('toast.Welcome')}` })

    } catch (error) {
      this.$toast.open({ type: 'error', message: `${this.$t('toast.Something went wrong')}` })
      this.error = true
    }
  }

  async showWrongAccountNotice() {
    const confirmComponent = await OverlayController.loadComponent(
      ConfirmModal,
      {
        props: {
          text: this.$t('login.confirmWrongAccount')
        }
      }
    )
  }
}
