









import { Component, Vue, Prop } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import ResetPasswordForm from '@/components/ResetPasswordForm.vue'

@Component<ResetPasswordView>({
  components: {
    Header,
    ResetPasswordForm
  }
})
export default class ResetPasswordView extends Vue {
  get email() {
    return this.$route.query.email
  }

  get token() {
    return this.$route.query.token
  }
}
