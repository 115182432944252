import VueI18n from 'vue-i18n'
import { de } from './language.de'
import Vue from 'vue'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: { de }
})
