



















































import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import ContainerCard from '@/components/ContainerCard.vue'
import UpdateCompany from '@/graphql/companyService/UpdateCompany.gql'
import { IUpdateCompanyData } from '@/graphql/companyService/interfaces/updateCompany.interface'
import GetMyCompany from '@/graphql/companyService/GetMyCompany.gql'

@Component<CompanyProfileForm>({
  components: {
    ContainerCard
  },
  apollo: {
    company: {
    	query: GetMyCompany,
	manual: true,
	// @ts-ignore
	result({data: {getMyCompany}}) {
    // @ts-ignore
    const paymentInfo = getMyCompany.paymentInfo;
		// @ts-ignore
		this['company'] = getMyCompany
		// @ts-ignore
		this['company'].bic = paymentInfo != undefined ? paymentInfo.bic : null;
		// @ts-ignore
		this['company'].recipient = paymentInfo != undefined ? paymentInfo.recipient : null;
		// @ts-ignore
		this['company'].iban = paymentInfo != undefined ? paymentInfo.iban : null;
	    }
    }
  }
})
export default class CompanyProfileForm extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>
  }

  company = {}
  async submit() {
    const valid = await this.$refs.observer.validate()
    if (!valid) { return }
    try {
      const { data } = await this.$apollo.mutate<IUpdateCompanyData>({
        mutation: UpdateCompany,
        variables: this.company
      })
      if (data && data.updateCompany === 'success') {
	      this.$toast.open({ type: 'success', message: `${this.$t('toast.success.generic')}` })
      } else {
        this.$toast.open({ type: 'error', message: `${this.$t('toast.Something went wrong')}` })
      }
    } catch(error) {
      this.$toast.open({ type: 'error', message: `${this.$t('toast.errors.' + error.message)}` })
    }
  }
}
