






import { Component, Vue, Prop } from 'vue-property-decorator'
import OverlayContainer from '@/components/OverlayContainer.vue'

import { OverlayController } from '@/lib/utils/OverlayController'

@Component<App>({
  components: {
    OverlayContainer
  }
})
export default class App extends Vue {
  mounted() {
    OverlayController.registerOverlayContainer(this.$refs.modal as any)
  }
}
