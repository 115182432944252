








export default {
    name: 'FaqItem',
    props: {
        question: {
            type: String,
            required: true
        },
        answer: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        toggleAnswer() {
            // @ts-ignore
            this.isOpen = !this.isOpen;
        }
    }
}
